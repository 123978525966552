import React from 'react';
import { Card, Carousel } from 'react-bootstrap';
import './TipInfo.css'; 

function TipInfoHome() {
    return (
        <Card>
          <Card.Body>
            <Card.Title className="tip-info-title">Free Cricket Prediction Tips</Card.Title>
            <Card.Text>
              Cricket is a very famous and most loved game, played with bat and ball between two teams of eleven players. It is said to be a second religion for the cricket lovers. When the cricket season begins the entire nation joins together to celebrate cricket season as a festival and pulls for their most loved teams and players. Apart of this fact, cricket has turned into an efficient and famous game on which to Predict and a source of income for cricket bettors. As the matches warm up, so does the Prediction activity on the most loved teams and players. Be that as it may, hang on; how might one predict effectively, without causing adversities? There is no rocket science behind cricket Prediction tips effectively. Bhaiji free online Cricket Prediction tips will assist you in becoming a specialist in Prediction. Follow our free Prediction tips by our cricket experts and enhance your Prediction techniques and get the best outcomes without repeating your previous mistakes. You can get and enjoy Prediction tips of best tipper for all running and upcoming series and tournaments such as IPL, World Cup, Big Bash League (BBL), Natwest T20 Blast, CPL and many more.
            </Card.Text>
            <Carousel>
              <Carousel.Item>
                <p>Don’t cross your limits: While Prediction, never ever predict out of your cutoff points. On the off chance you don’t have the funds to cover your Prediction amount, it’s fitting remain well inside your limits, to avoid any kind of future unreasonable heavy losses.</p>
              </Carousel.Item>
              <Carousel.Item>
                <p>Follow winning trends: Like any savvy strategist, it’s basic to follow the winning trends, and then decide which team to predict on. As is the case with any sport, the winning teams will always keep moving up, allowing you to make profits always.</p>
              </Carousel.Item>
              <Carousel.Item>
                <p>Believe and follow your gut feelingg: It’s been demonstrated that your gut feeling is always strong enough to overwhelm any trends. If you feel strongly about a team or a player, you should go ahead and predict on it. If you can back up your gut feeling with some past wins, you can end up invulnerable.</p>
              </Carousel.Item>
              {/* ... other carousel items ... */}
            </Carousel>
          </Card.Body>
        </Card>
      );
    }

export default TipInfoHome;
