import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.jpg";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">We're</strong>
            </h1>
            <Aboutcard />
          </Col>
          {/* <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col> */}
        </Row>
        <h2 className="project-heading">
          Our Strategic <strong className="purple">Partnerships </strong>
        </h2>

        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          As an Associate Partner of WCBA (World Cricket Prediction Association) and in collaboration with leading prediction websites like Betfair, Bet365, and Marathon Bet, we have fortified our position in the industry. These strategic partnerships enable us to offer exclusive insights and enhance the prediction experience for our clients globally.
        </p>
        </blockquote>
        <br/> 
        <br/> 

        <h2 className="project-heading">Commitment to 
          <strong className="purple"> Excellence</strong> 
        </h2>

        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          At BHAI JI, excellence is not just a goal, but a standard. Our commitment to providing accurate and responsible prediction tips is unwavering. We empower our clients with knowledge and strategies, ensuring they have the best possible advice for making informed prediction decisions. Our dedication to excellence is what makes us the trusted choice for cricket enthusiasts worldwide.
          NOW BHAI JI BETTING TIPS is Associate Partner of WCBA (World Cricket Prediction Association). (Website-www.wcba.online ) Along with Betfair, Bet365 Marathon Bet Bookies FREE BETS BET STARS Which are World’s biggest Prediction websites And Bhai ji is only TIPPER who is Associate partner of WCBA. Now BHAI JI BETTING TIPS is RANKED as ASIA’S No.1 and world’s no.3 TIPPER ACCORDING TO WCBA (World Cricket Prediction association) also. Which is on basis of Client base and Actual reports we provide. BHAI JI is ONLY registered TIPPER in world by WCBA. WCBA (WORLD CRICKET BETTING ASSOCIATION)- WCBA IS AUTHORITY WHICH GIVE LICENSE TO ALL WEBSITES RELATED TO BETTING Like- Betfair, Bet365, Marathon Bet etc. ONLY GENUINE CAN GET LICENCE FROM WCBA. SO NOW WE CAN SAY BAAKI SAB FAKE ONLY BHAI JI (The King) is real tipper who gives actual tips and reports. BOOM BOOM BE WITH KING BE KING. BE WITH GENUINE GET GENUINE. We are only tipper in world who is registered and associated with WCBA (world cricket prediction association) official website- www.wcba.online Our partners are biggest prediction website like betfair, bet365, free predictions, bookies, star predictions, marathon predictions etc. Welcome to BHAI JI (The King) TEAM. YOU ARE WITH BIGGEST TIPS TEAM OF ASIA. Right now we are operating in SOUTH AFRICA, WEST INDIES, NEWZELAND, ETHOPIA, LA, DENMARK, POLLAND, BELGIUM, BRAZIL, SINGAPORE, UAE, ENGLAND and COMPLETE ASIA, MANY MORE. BE WITH KING AND BE KING. BOOM BOOM. BE WITH GENUINE AND GET GENUINE. And for more details go to our website- www.bhaijicricketbettingtips.com

        </p></blockquote>

        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <br/><br/>
          <b>NOTE - </b> These are predictions based on past and current team and player performance. Also, in our predictions, we consider current match playing conditions and pitch conditions. We dont operate in India, nepal, pakistan, bangladesh, Sri lanka, afganistan, UAE, and countries that dont allow predictions of matches. These predictions are completely for entertainment purposes only and not for putting and money or stakes on the basis of these predictions. We are completely against any kind of prediction activities, and we never promote anything related to prediction or gambling.
        </p></blockquote>
        {/* <Toolstack />

        <Github /> */}
      </Container>
    </Container>
  );
}

export default About;
