import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Behind the Scenes with The Cricket Prediction <span className="purple"> Gurus</span>
            <br/>
            <br />
          Founded in the year 2010, Bhaiji Cricket Prediction tips is a pioneer of its field. Essentially Bhaiji Prediction 
          tips is a group of 19 people who have more than 10 years of experience in Cricket Prediction world. Collectively 
          we run 5 Different Websites on more than 5 different countries. We are the lions in cricket Prediction as compared 
          to the rest of the werewolves. This allows us to be the best platform for cricket Prediction tips for all matches 
          to our valuable clients. We provide every cricket fan an opportunity to compete and challenge with the best gaming 
          minds.
        </p>
        

          <p style={{ color: "rgb(155 126 172)" }}>
            "Luck is what happens when prediction meets Bhaiji!"{" "}
          </p>
          <footer className="blockquote-footer">The KING</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
