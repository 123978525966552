import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import BlogTips from "./BlogTips";
import TipInfoHome from "./TipInfoHome";
import Type from "./Type";
// import PushTips from "./PushTips"

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15, marginTop:60 }} className="heading">
              Predict Smart with Expert Cricket Insights and Tips!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
              From Toss to Triumph: 
              <br/>
                <strong className="main-name"> Predict Wisely, Win Big!</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            {/* <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col> */}
          </Row>
        </Container>
      </Container>
      <div><TipInfoHome/></div>
      <BlogTips/>
      <Home2 />
      
      
      {/* <PushTips/> */}

    </section>
  );
}

export default Home;
